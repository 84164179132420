import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card } from 'react-bootstrap';
import AddKeys from 'pages/common/modals/AddKeys';
import DoKYC from 'pages/common/modals/DoKYC';
import RemoveKeys from './modals/RemoveKeys';
import ConfirmMail from 'pages/common/modals/ConfirmMail';
import FormTitle from 'pages/common/FormTitle';
import { WalletPage as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import binanceCard from 'assets/img/hodlie/wallet/binance.png';
import SetBnbDiscountModal from 'pages/common/modals/SetBnbDiscount';
import LoadingSpinner from 'components/utilities/LoadingSpinner';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import { blueColor } from 'staticData/common';
import { coinsData } from 'services/coins/common';
import InfoPopover from 'components/utilities/InfoPopover';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import 'assets/scss/custom/tables.scss';
import 'assets/scss/custom/walletPage.scss';
import { breakpoints } from 'helpers/utils';
import { trackEvent } from 'services/externals/mixpanel';
import { useLocation } from 'react-router-dom';
import { cleanCache } from 'services/cache';
import { useHistory } from 'react-router-dom';

const Wallet = ({ realoadUserInfo, showCoins = false }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  // can get data from query parameter
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const open_next = queryParameters.get('next') === 'open';
  const fastApiConnected = queryParameters.get('fast_api') === 'connected';
  const error_id = queryParameters.get('error_id');
  const routerHistory = useHistory();

  const [addKeysShow, setAddKeysShow] = useState(false);
  const [removeKeysShow, setRemoveKeysShow] = useState(false);
  const [ConfirmMailShow, setConfirmMailShow] = useState(false);
  const [exchange, setExchange] = useState('');
  const [doKYCShow, setDoKYCShow] = useState(false);
  const [kycStatus, setKycStatus] = useState(userInfos.kyc_status);
  const [coinInfos, setCoinInfos] = useState({});
  const [loadedCoinInfos, setLoadedCoinInfos] = useState(false);
  const [allocations, setAllocations] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [walletData, setWalletData] = useState({});
  const [bnbDiscountModalShow, setBnbDiscountModalShow] = useState(false);
  const [bnbDiscountStatus, setBnbDiscountStatus] = useState(false);
  const [fastAPiError, setFastApiError] = useState('');
  const dataPerTablePage = 5;
  const showedExchange = 'binance';
  const kyc_accepted_status = ['approved', 'contact_support', 'processing'];
  const getStep = () => {
    var returnValue;
    if (!userInfos.email_confirmed) returnValue = 0;
    else if (!kyc_accepted_status.includes(userInfos.kyc_status))
      returnValue = 1;
    else if (!userInfos.connected_exchanges.includes('binance'))
      returnValue = 2;
    else returnValue = 3;
    return returnValue;
  };
  const [step, setStep] = useState(getStep());

  const coinOrderedInfos = Object.values(coinsData);

  const defaultCoins = [
    {
      code: 'BTC',
      name: 'Bitcoin',
      quantity: 0,
      equivalent: 0
    },
    {
      code: 'ETH',
      name: 'Ethereum',
      quantity: 0,
      equivalent: 0
    },
    {
      code: 'SOL',
      name: 'Solana',
      quantity: 0,
      equivalent: 0
    },
    {
      code: 'USDC',
      name: 'USD Coin',
      quantity: 0,
      equivalent: 0
    }
  ];

  const coinTableData = input => {
    // Create a mapping object for coin names
    const coinNameMap = coinOrderedInfos.reduce((acc, coin) => {
      acc[coin.code] = coin.name;
      return acc;
    }, {});

    let output = {};

    output[showedExchange] = []; // Initialize an array for the exchange

    // Calculate the total equivalent for percentage calculation
    let totalEquivalent = 0;
    for (let coin in input)
      totalEquivalent += input[coin].amount_allocated * input[coin].price;

    // Iterate over each coin
    for (let coin in input) {
      let quantity = Math.max(input[coin].amount_allocated, 0);
      let remainingInExchange = Math.max(
        input[coin].amount_exchange - quantity,
        0
      );
      let equivalent = Math.max(quantity * input[coin].price, 0);
      let equivalentRemainingInExchange = Math.max(
        remainingInExchange * input[coin].price,
        0
      );
      let hodliePercentage = Math.max((equivalent / totalEquivalent) * 100, 0);

      // Add the coin to the output array
      output[showedExchange].push({
        code: coin,
        name: coinNameMap[coin] || coin,
        quantity,
        equivalent,
        remainingInExchange,
        equivalentRemainingInExchange,
        hodliePercentage
      });
    }
    return output;
  };

  const columns = [
    {
      accessor: 'name',
      Header: textLang.name[lang],
      headerProps: {
        className: 'text-900',
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      cellProps: {
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      Cell: rowData => {
        const { name, code } = rowData.row.original;
        return (
          <Flex className="align-items-baseline">
            <h6 className="mb-0">
              {coinsData?.[code] && (
                <img
                  src={coinsData?.[code].img}
                  alt={name}
                  width="20px"
                  className="me-2"
                />
              )}
              {window.innerWidth < breakpoints['sm'] ? code : name}
            </h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'equivalent',
      Header:
        window.innerWidth < breakpoints['sm'] ? '$' : textLang.equivalent[lang],
      headerProps: {
        className: 'text-900',
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      cellProps: {
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      Cell: rowData => {
        const { equivalent, hodliePercentage } = rowData.row.original;
        return (
          <Flex className="align-items-baseline">
            <h6 className="mb-0">${equivalent.toFixed(2)}</h6>
            <p className="mb-0 ps-1 fs--2">
              ({hodliePercentage ? hodliePercentage.toFixed(2) : 0}%)
            </p>
          </Flex>
        );
      }
    },
    {
      accessor: 'quantity',
      Header:
        window.innerWidth < breakpoints['sm']
          ? 'Coin'
          : textLang.quantity[lang],
      headerProps: {
        className: 'text-900',
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      cellProps: {
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      Cell: rowData => {
        const { quantity, code } = rowData.row.original;
        return quantity === 0
          ? 0
          : quantity.toFixed(coinsData?.[code]?.decimalPrecision || 2);
      }
    },
    {
      accessor: 'equivalentRemainingInExchange',
      Header:
        window.innerWidth < breakpoints['sm'] ? '$' : textLang.equivalent[lang],
      headerProps: {
        className: 'text-900',
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      cellProps: {
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      Cell: rowData => {
        const { equivalentRemainingInExchange } = rowData.row.original;
        return (
          <Flex className="align-items-baseline">
            <h6 className="mb-0">
              ${equivalentRemainingInExchange.toFixed(2)}
            </h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'remainingInExchange',
      Header:
        window.innerWidth < breakpoints['sm']
          ? 'Coin'
          : textLang.quantity[lang],
      headerProps: {
        className: 'text-900',
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      cellProps: {
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      Cell: rowData => {
        const { remainingInExchange, code } = rowData.row.original;
        return remainingInExchange === 0
          ? 0
          : remainingInExchange.toFixed(
              coinsData?.[code]?.decimalPrecision || 2
            );
      }
    }
  ];

  const calculateAllocations = input => {
    let result = {
      totalAllocated: 0,
      totalAvailable: 0
    };

    for (let key in input) {
      let allocated = 0;
      let available = 0;

      for (let coin of input[key]) {
        allocated += coin.equivalent;
        available += coin.equivalentRemainingInExchange;
      }

      result[key] = { allocated, available };
      // Exclude 'virtual' key from total calculations
      if (key !== 'virtual') {
        result.totalAllocated += allocated;
        result.totalAvailable += available;
      }
    }
    setAllocations(result);
    return result;
  };

  const handleShowModal = (exchange = null, remove = false) => {
    if (step === 0) setConfirmMailShow(true);
    else {
      if (exchange) setExchange(exchange);
      if (kycStatus === 'todo') {
        setDoKYCShow(true);
      } else if (remove) {
        setRemoveKeysShow(true);
      } else {
        setAddKeysShow(true);
      }
    }
  };

  const handleReloadUserInfos = async () => {
    setLoaded(false);
    realoadUserInfo();
  };

  const statusColor = wallet => {
    if (wallet.error) return 'warning';
    else if (wallet.connected) return 'success';
    else return 'danger';
  };

  const walletMapData = {
    Binance: {
      img: binanceCard,
      guideLink: '/connect-binance-wallet',
      depositGuide: '/deposit-binance',
      convertGuide: '/convert-on-binance',
      available: true,
      userCanContact: true
    }
  };

  const orderWallets = wallets => {
    let tmpOrderWallets = {};
    Object.keys(walletMapData).map(walletName => {
      if (walletName in wallets) {
        tmpOrderWallets[walletName] = wallets[walletName];
      }
    });
    return tmpOrderWallets;
  };

  const getExchangesOverview = async () => {
    let exchangeInfos = await endpointInterface(
      lang,
      'backend',
      'getUserExchangesOverview',
      'get',
      true,
      {},
      true,
      true,
      1,
      1
    );
    if (!exchangeInfos.validResponse) {
      toast.error(exchangeInfos.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    } else return exchangeInfos.data;
  };

  const getWalletAvailability = async () => {
    var params = {
      virtual: false
    };
    let availabilityResponse = await endpointInterface(
      lang,
      'backend',
      'walletAvailability_binance',
      'get',
      true,
      params,
      true,
      true,
      1,
      1
    );
    if (availabilityResponse.validResponse) {
      let localCointTableData = coinTableData(availabilityResponse.data);
      setCoinInfos(localCointTableData);
      calculateAllocations(localCointTableData);
      setLoadedCoinInfos(true);
    }
  };

  const openDiscountModal = actualStatus => {
    setBnbDiscountStatus(actualStatus);
    setBnbDiscountModalShow(true);
  };

  const handleSetBnbDiscount = async newBnbDiscountValue => {
    let form = new FormData();
    form.append('status', newBnbDiscountValue);
    let updateBnbStatusResponse = await endpointInterface(
      lang,
      'backend',
      'editBnbBurnStatus',
      'post',
      true,
      form
    );
    if (updateBnbStatusResponse.validResponse) {
      window.location.reload();
      await cleanCache(0);
    } else {
      toast.error(updateBnbStatusResponse.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!loaded) {
        setCoinInfos({});
        setWalletData({});
        setStep(getStep());
        try {
          if (
            (!kyc_accepted_status.includes(userInfos.kyc_status) ||
              !userInfos.exchanges?.length) &&
            open_next
          ) {
            handleShowModal('Binance');
            queryParameters.delete('next');
            routerHistory.push(`?${queryParameters.toString()}`);
          } else if (kyc_accepted_status.includes(userInfos.kyc_status)) {
            await getWalletAvailability();
          }
          // track event fast API
          if (fastApiConnected || error_id) {
            trackEvent('exchangeKeys', {
              status_event: fastApiConnected ? 'add' : 'error',
              type: 'fastApi',
              error_id: error_id,
              page: window.location.pathname
            });
            if (error_id) setFastApiError(error_id);
            handleShowModal('Binance');
            queryParameters.delete('fast_api');
            queryParameters.delete('next');
            routerHistory.push(`?${queryParameters.toString()}`);
          }
          let tmpExchanges = await getExchangesOverview();
          setWalletData(orderWallets(tmpExchanges));
          setLoaded(true);
        } catch (error) {
          console.error(error.message);
        }
      }
    };
    fetchData();
  }, [userInfos?.connected_exchanges?.length, loaded]);

  if (!loaded) {
    return (
      <>
        {showCoins && (
          <Row md={12}>
            <FormTitle
              icon="wallet"
              title={textLang.title[lang]}
              subtitle={textLang.subtitle[lang]}
            />
          </Row>
        )}
        <Row>
          <Card
            style={{
              backgroundColor: 'var(--falcon-body-bg)',
              boxShadow: 'none'
            }}
          >
            <Card.Body>
              <Row>
                <Col xs={12} lg={5} xxl={4}>
                  <Card.Img
                    style={{ borderRadius: '19%' }}
                    src={walletMapData['Binance'].img}
                  />
                </Col>
                <Col xs={12} lg={7} xxl={8}>
                  <Card.Title as="h3" className="mb-3 mt-4">
                    {'Binance'}
                  </Card.Title>
                  <Col xs={4} md={2}>
                    <LoadingSpinner></LoadingSpinner>
                  </Col>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </>
    );
  }

  return (
    <>
      <ConfirmMail
        ConfirmMailShow={ConfirmMailShow}
        setConfirmMailShow={setConfirmMailShow}
        step={step}
      />
      <DoKYC
        doKYCShow={doKYCShow}
        setDoKYCShow={setDoKYCShow}
        step={step}
        setStep={setStep}
        setKycStatus={setKycStatus}
        setAddKeysShow={setAddKeysShow}
      />
      <AddKeys
        addKeysShow={addKeysShow}
        setAddKeysShow={setAddKeysShow}
        exchange={exchange}
        setWalletData={setWalletData}
        walletData={walletData}
        walletMapData={walletMapData}
        realoadUserInfo={handleReloadUserInfos}
        step={step}
        setStep={setStep}
        error_id={fastAPiError}
        connected={fastApiConnected}
      />
      <RemoveKeys
        removeKeysShow={removeKeysShow}
        setRemoveKeysShow={setRemoveKeysShow}
        exchange={exchange}
        setWalletData={setWalletData}
        walletData={walletData}
        realoadUserInfo={handleReloadUserInfos}
      />

      <SetBnbDiscountModal
        modalShow={bnbDiscountModalShow}
        setModalShow={setBnbDiscountModalShow}
        active={bnbDiscountStatus}
        setBnbDiscount={handleSetBnbDiscount}
      />
      {showCoins && (
        <Row md={12}>
          <FormTitle
            icon="wallet"
            title={textLang.title[lang]}
            subtitle={textLang.subtitle[lang]}
          />
        </Row>
      )}
      {Object.entries(walletData).map(
        ([walletName, wallet], index) =>
          Object.keys(walletMapData).includes(walletName) && (
            <>
              {index !== 0 && <hr />}
              <Row key={walletName}>
                <Card
                  style={{
                    backgroundColor: 'var(--falcon-body-bg)',
                    boxShadow: 'none'
                  }}
                >
                  <Card.Body>
                    <Row>
                      <Col xs={12} lg={5} xxl={4}>
                        <Card.Img
                          style={{ borderRadius: '19%' }}
                          src={walletMapData[walletName].img}
                        />
                      </Col>
                      <Col xs={12} lg={7} xxl={8}>
                        <Card.Title as="h3" className="mb-3 mt-4">
                          <Flex>
                            {walletName}
                            <SoftBadge
                              className="fs--2 ms-2 mt-1"
                              pill
                              bg={
                                !walletMapData[walletName].available
                                  ? 'secondary'
                                  : statusColor(wallet)
                              }
                            >
                              {!walletMapData[walletName].available
                                ? 'Coming Soon'
                                : wallet.error
                                ? textLang.error[lang]
                                : wallet.connected
                                ? textLang.connected[lang]
                                : textLang.notConnected[lang]}
                            </SoftBadge>
                            {wallet.error && (
                              <InfoPopover
                                Text={textLang.errorText[lang]}
                              ></InfoPopover>
                            )}
                            {wallet.connected &&
                              walletName == 'Binance' &&
                              kyc_accepted_status.includes(
                                userInfos.kyc_status
                              ) && (
                                <SoftBadge
                                  className="fs--2 ms-2 mt-1"
                                  pill
                                  bg={
                                    wallet.extras && wallet.extras.bnbDiscount
                                      ? 'info'
                                      : 'secondary'
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      wallet.extras && wallet.extras.bnbDiscount
                                        ? 'percent'
                                        : 'ban'
                                    }
                                  />

                                  {' ' + textLang.bnbDiscountBadge[lang]}
                                </SoftBadge>
                              )}
                          </Flex>
                        </Card.Title>
                        <Row className="pb-3">
                          <Col xs={12} lg={7}>
                            {wallet.connected &&
                            kyc_accepted_status.includes(
                              userInfos.kyc_status
                            ) ? (
                              <>
                                <Flex className="mb-1">
                                  <h6>
                                    <FontAwesomeIcon
                                      icon="wallet"
                                      className="me-2"
                                    />
                                    {textLang.wallet[lang]}:
                                    {!wallet.error && (
                                      <span className="ms-2 fs-1 text-700">
                                        $
                                        {Number(
                                          walletData[walletName].amount
                                        ).toFixed(2)}
                                      </span>
                                    )}
                                  </h6>
                                  {!wallet.error && (
                                    <InfoPopover
                                      Text={
                                        textLang.onExchangePre[lang] +
                                        walletName +
                                        textLang.onExchangePost[lang]
                                      }
                                    ></InfoPopover>
                                  )}
                                </Flex>
                                <Flex className="d-none d-sm-flex">
                                  <h6 style={{ color: blueColor }}>
                                    <FontAwesomeIcon
                                      icon="robot"
                                      className="me-2"
                                    />
                                    {textLang.onHodlie[lang]}:
                                    {!wallet.error &&
                                      allocations[walletName.toLowerCase()]
                                        ?.allocated != null && (
                                        <span className="ms-2 fs-1 text-700">
                                          $
                                          {Number(
                                            allocations[
                                              walletName.toLowerCase()
                                            ].allocated
                                          ).toFixed(2)}
                                        </span>
                                      )}
                                  </h6>
                                  {!wallet.error && (
                                    <InfoPopover
                                      Text={textLang.capValue[lang]}
                                    ></InfoPopover>
                                  )}
                                </Flex>
                              </>
                            ) : walletMapData[walletName].available &&
                              (kycStatus === 'todo' ||
                                kyc_accepted_status.includes(
                                  userInfos.kyc_status
                                )) ? (
                              <>
                                {textLang.connect[lang] +
                                  walletName +
                                  textLang.api[lang]}
                              </>
                            ) : kycStatus === 'processing' ? (
                              <>{textLang.processing[lang]}</>
                            ) : kycStatus === 'contact_support' ? (
                              <>
                                {textLang.contactSupport[lang]}
                                <a href="mailto:kyc@hodlie.net">
                                  kyc@hodlie.net
                                </a>
                                {textLang.contactSupport1[lang]}
                              </>
                            ) : null}
                          </Col>
                          {walletMapData[walletName].available && (
                            <Col xs={12} lg={5} className="ps-lg-3">
                              <Card.Text>
                                <small>
                                  <FontAwesomeIcon
                                    icon="link"
                                    className="me-2"
                                  />
                                  {textLang.follow[lang] +
                                    walletName +
                                    textLang.to[lang]}
                                  {walletMapData[walletName].depositGuide && (
                                    <>
                                      <a
                                        href={
                                          walletMapData[walletName].depositGuide
                                        }
                                        target="__blank"
                                      >
                                        {textLang.deposit[lang]}
                                      </a>
                                      ,
                                      <br className="d-md-none d-xxl-block" />
                                    </>
                                  )}
                                  {walletMapData[walletName].convertGuide && (
                                    <a
                                      href={
                                        walletMapData[walletName].convertGuide
                                      }
                                      target="__blank"
                                    >
                                      {textLang.convert[lang]}
                                    </a>
                                  )}
                                  {walletMapData[walletName].guideLink && (
                                    <>
                                      {textLang.or[lang]}
                                      <a
                                        href={
                                          walletMapData[walletName].guideLink
                                        }
                                        target="__blank"
                                      >
                                        {textLang.conn[lang] +
                                          textLang.wall[lang]}
                                      </a>
                                    </>
                                  )}
                                </small>
                              </Card.Text>
                            </Col>
                          )}
                          {!walletMapData[walletName].available &&
                            walletMapData[walletName].userCanContact && (
                              <Row className="pb-3">
                                <Col xs={12} lg={12}>
                                  {textLang.hear[lang]}
                                  <a href="mailto:earlybird@hodlie.net">
                                    earlybird@hodlie.net
                                  </a>
                                </Col>
                              </Row>
                            )}
                        </Row>
                        <Col className="ps-3">
                          {step !== 3 &&
                            walletMapData[walletName].available &&
                            (kycStatus === 'todo' ||
                            kyc_accepted_status.includes(
                              userInfos.kyc_status
                            ) ? (
                              <IconButton
                                className="mb-2 ms-2"
                                variant={
                                  kycStatus === 'todo'
                                    ? 'success'
                                    : wallet.connected
                                    ? 'secondary'
                                    : 'primary'
                                }
                                icon={kycStatus === 'todo' ? 'user' : 'key'}
                                size="sm"
                                onClick={() => handleShowModal(walletName)}
                              >
                                {kycStatus === 'todo'
                                  ? textLang.connectButton[lang]
                                  : textLang.addKeys[lang]}
                              </IconButton>
                            ) : null)}
                          {wallet.connected &&
                            kyc_accepted_status.includes(
                              userInfos.kyc_status
                            ) && (
                              <IconButton
                                className="mb-2 ms-2"
                                variant="danger"
                                icon="trash"
                                size="sm"
                                onClick={() =>
                                  handleShowModal(walletName, true)
                                }
                              >
                                {textLang.remove[lang]}
                              </IconButton>
                            )}
                          {wallet.connected &&
                            walletName == 'Binance' &&
                            kyc_accepted_status.includes(
                              userInfos.kyc_status
                            ) && (
                              <IconButton
                                className="mb-2 ms-2"
                                variant="info"
                                icon="edit"
                                size="sm"
                                onClick={() =>
                                  openDiscountModal(
                                    wallet.extras && wallet.extras.bnbDiscount
                                  )
                                }
                              >
                                {textLang.bnbDiscountBadge[lang]}
                              </IconButton>
                            )}
                        </Col>
                      </Col>
                    </Row>
                    {loadedCoinInfos &&
                      coinInfos[walletName.toLowerCase()] &&
                      Object.keys(coinInfos[walletName.toLowerCase()]).length >
                        0 &&
                      showCoins && (
                        <>
                          <Row className="pt-3">
                            <Flex className="mb-2">
                              <h5 className="d-none d-sm-block">
                                {textLang.hodlieText[lang] +
                                  ': $' +
                                  Number(
                                    allocations[walletName.toLowerCase()]
                                      .allocated
                                  ).toFixed(2)}
                              </h5>
                              <Flex className="d-sm-none">
                                <h6 style={{ color: blueColor }}>
                                  <FontAwesomeIcon
                                    icon="robot"
                                    className="me-2"
                                  />
                                  {textLang.onHodlie[lang]}:
                                  <span className="ms-2 fs-1 text-700">
                                    $
                                    {Number(
                                      allocations[walletName.toLowerCase()]
                                        .allocated
                                    ).toFixed(2)}
                                  </span>
                                </h6>
                              </Flex>
                              <span className="ps-1">
                                <InfoPopover
                                  Text={textLang.hodlieUndermanagement[lang]}
                                />
                              </span>
                            </Flex>
                            <AdvanceTableWrapper
                              columns={columns.filter(
                                column =>
                                  ![
                                    'remainingInExchange',
                                    'equivalentRemainingInExchange'
                                  ].includes(column.accessor)
                              )}
                              data={
                                (
                                  coinInfos[walletName.toLowerCase()] || []
                                ).filter(row => row.quantity !== 0).length > 0
                                  ? (coinInfos[walletName.toLowerCase()] || [])
                                      .filter(row => row.quantity !== 0)
                                      .sort(
                                        (a, b) => b.equivalent - a.equivalent
                                      )
                                  : defaultCoins
                              }
                              sortable
                              pagination
                              perPage={dataPerTablePage}
                            >
                              <AdvanceTable
                                table
                                headerClassName="bg-40 text-nowrap align-middle d-none extra-class d-sm-table-group-header"
                                rowClassName="align-middle white-space-nowrap border-bottom-table"
                                tableProps={{
                                  bordered: true,
                                  striped: true,
                                  className: 'fs-10 mb-0 overflow-hidden'
                                }}
                              />
                              <div className="mt-3">
                                {coinInfos[walletName.toLowerCase()].filter(
                                  row => row.quantity !== 0
                                ).length > dataPerTablePage && (
                                  <AdvanceTableFooter
                                    rowCount={
                                      (
                                        coinInfos[walletName.toLowerCase()] ||
                                        []
                                      ).filter(row => row.quantity !== 0).length
                                    }
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                  />
                                )}
                              </div>
                            </AdvanceTableWrapper>
                          </Row>
                          <Row className="pt-3">
                            <Flex className="mb-2">
                              <h5 className="d-none d-sm-block">
                                {textLang.available[lang] +
                                  walletName +
                                  ': $' +
                                  Number(
                                    allocations[walletName.toLowerCase()]
                                      .available
                                  ).toFixed(2)}
                              </h5>
                              <Flex className="d-sm-none">
                                <h6>
                                  <FontAwesomeIcon
                                    icon="wallet"
                                    className="me-2"
                                  />
                                  {textLang.available[lang] + walletName}:
                                  <span className="ms-2 fs-1 text-700">
                                    $
                                    {Number(
                                      allocations[walletName.toLowerCase()]
                                        .available
                                    ).toFixed(2)}
                                  </span>
                                </h6>
                              </Flex>
                              <span className="ps-1">
                                <InfoPopover
                                  Text={textLang.exchangeUndermanagement[lang]}
                                />
                              </span>
                            </Flex>
                            <AdvanceTableWrapper
                              columns={columns.filter(
                                column =>
                                  ![
                                    'quantity',
                                    'equivalent',
                                    'hodliePercentage'
                                  ].includes(column.accessor)
                              )}
                              data={(coinInfos[walletName.toLowerCase()] || [])
                                .filter(row => row.remainingInExchange !== 0)
                                .sort(
                                  (a, b) =>
                                    Number(b.equivalentRemainingInExchange) -
                                    Number(a.equivalentRemainingInExchange)
                                )}
                              sortable
                              pagination
                              perPage={dataPerTablePage}
                            >
                              <AdvanceTable
                                table
                                headerClassName="text-nowrap align-middle d-none extra-class d-sm-table-group-header"
                                rowClassName="align-middle white-space-nowrap border-bottom-table"
                                tableProps={{
                                  bordered: true,
                                  striped: true,
                                  className: 'fs-10 mb-0 overflow-hidden'
                                }}
                              />
                              <div className="mt-3">
                                {coinInfos[walletName.toLowerCase()].filter(
                                  row => row.remainingInExchange !== 0
                                ).length > dataPerTablePage && (
                                  <AdvanceTableFooter
                                    rowCount={
                                      (
                                        coinInfos[walletName.toLowerCase()] ||
                                        []
                                      ).filter(
                                        row => row.remainingInExchange !== 0
                                      ).length
                                    }
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                  />
                                )}
                              </div>
                            </AdvanceTableWrapper>
                          </Row>
                        </>
                      )}
                  </Card.Body>
                </Card>
              </Row>
            </>
          )
      )}
    </>
  );
};

Wallet.propTypes = {
  realoadUserInfo: PropTypes.func.isRequired,
  showCoins: PropTypes.bool
};

export default Wallet;
